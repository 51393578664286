import React from 'react';
import { Table, Button, Col, Popconfirm } from 'antd';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import moment from 'moment';
import 'moment/locale/pt-br';
import styles from './style.module.scss';
import imgnaodisponivel from '../../assets/imgnaodisponivel.png'

const formatarValor = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor);
};

const formatarDocumento = (documento) => {
  const apenasNumeros = documento.replace(/\D/g, '');
  if (apenasNumeros.length === 11) {
    return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (apenasNumeros.length === 14) {
    return apenasNumeros.replace(/(\d{2})(\d{4})(\d{4})(\d{2})/, '$1.$2.$3/$4-');
  }
  return documento;
};

const mapStatus = (status) => {
  switch (status) {
    case 'ativo':
      return 'Ativo';
    case 'inativo':
      return 'Inativo';
    case 'deletado':
      return 'Deletado';
    default:
      return 'Desconhecido'; // Valor padrão se o status não corresponder a nenhum caso
  }
};

const ProdutosTable = ({ data, onEdit, onDelete }) => {
  const columns = [
    {
      title: 'Produto',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (text, record) => {
        const imageUrl = record.arquivos && record.arquivos[0] ? `https://rangopassbucket.s3.amazonaws.com/${record.arquivos[0].key}` : '';
        return (
          <div className={styles.containerRestaurante}>
            <div className={styles.imagemRestaurante}>
              {imageUrl ? <img src={imageUrl} /> : <img src={imgnaodisponivel}></img>}
            </div>
            <div className={styles.texto}>
              <a className={styles.titulo}>{text}</a>
              <div className={styles.descricao}>{record.descricao}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Restaurante',
      dataIndex: 'restaurante',
      key: 'restaurante',
      render: (restaurante) => (
        <div>
          <a className={styles.titulo} >{restaurante.nome}</a>
          <div style={{ fontSize: '12px' }}>{formatarDocumento(restaurante.valorDoc)}</div>
        </div>
      ),
      ellipsis: {
        showTitle: false,
      },
      width: 150,
    },
    {
      title: 'Preço',
      dataIndex: 'valor',
      key: 'valor',
      render: (text) => formatarValor(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => mapStatus(text)
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (address, record) => (
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<AiOutlineEdit />}
            size={10}
            style={{ marginRight: '8px' }}
            onClick={() => onEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja excluir este produto?"
            onConfirm={() => onDelete(record)}
            okText="Sim"
            cancelText="Não"
          >
          <Button
            type="primary"
            shape="circle"
            icon={<AiOutlineDelete />}
            size={10}
          />
        </Popconfirm>
        </Col >
      ),
    },
  ];

return (
  <Table
    columns={columns}
    dataSource={data}
    scroll={{ x: 'max-content' }}
  />
);
};

export default ProdutosTable;
