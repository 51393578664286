// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__5sIXl {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}

.style_containerModal__1JGbL {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style_containerUpload__oZzmg {
  display: flex;
  justify-content: center;
}

.style_filters__St0R8 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 0.5rem;
  padding: 24px 0px;
  border-radius: 8px;
}

.style_inputsContainer__apuhO {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.style_inputWrapper__QI1JO, .style_selectWrapper__gYoUd {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Categorias/style.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;AAAF;;AAGA;EACE,SAAA;AAAF","sourcesContent":[".container {\n  width: 90%;\n  min-height: 100%;\n  margin: auto;\n  padding: 24px 0px;\n}\n\n.containerModal {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.containerUpload {\n  display: flex;\n  justify-content: center;\n}\n\n\n.filters {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  margin-bottom: .5rem;\n  padding: 24px 0px;\n  border-radius: 8px;\n}\n\n.inputsContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n}\n\n.inputWrapper, .selectWrapper {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__5sIXl`,
	"containerModal": `style_containerModal__1JGbL`,
	"containerUpload": `style_containerUpload__oZzmg`,
	"filters": `style_filters__St0R8`,
	"inputsContainer": `style_inputsContainer__apuhO`,
	"inputWrapper": `style_inputWrapper__QI1JO`,
	"selectWrapper": `style_selectWrapper__gYoUd`
};
export default ___CSS_LOADER_EXPORT___;
