// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__zKnVb {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}

.style_containerModal__LSWW9 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style_containerUpload__k86Mm {
  display: flex;
  justify-content: center;
}

.style_segmented__azDWR {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cupons/style.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".container {\n  width: 90%;\n  min-height: 100%;\n  margin: auto;\n  padding: 24px 0px;\n}\n\n.containerModal {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.containerUpload {\n  display: flex;\n  justify-content: center;\n}\n\n.segmented {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__zKnVb`,
	"containerModal": `style_containerModal__LSWW9`,
	"containerUpload": `style_containerUpload__k86Mm`,
	"segmented": `style_segmented__azDWR`
};
export default ___CSS_LOADER_EXPORT___;
