import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { api } from '../services/apiClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState('');

  const login = useCallback(async (user, password) => {
    try {
      const response = await api.post('/login', {
        user,
        password,
      });
      const { id, nome, email, token } = response.data.message;
      // const tipoUsuarioInt = parseInt(tipoUsuario, 10);
      const tipoUsuarioInt = 0;
      setUser({ id, nome, email, token });
      localStorage.setItem('RangoPassToken', token);
      return true;
    } catch (error) {
      console.log('Login failed:', error.message);
      return false;  // Alterado para retornar false em caso de erro
    }
  }, []);
  

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('RangoPassToken');
    window.location.href = '/';
  }, []);

  const checkAuthentication = useCallback(async () => {
    const authToken = localStorage.getItem('RangoPassToken');
    console.log('authToken', authToken)
    

    if (authToken) {
      try {
        const response = await api.get(`/user/dados`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('response', response.data)

        const { id, nome, email, token } = response.data.message;
        // const tipoUsuarioInt = parseInt(tipoUsuario, 10);

        setUser({ id, nome, email, token });
      } catch (error) {
        console.log('error', error)
        console.log('Erro ao verificar autenticação', error.message);
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  const isAuthenticated = useCallback(() => !!user, [user]);

  const isAdmin = useCallback(() => !!user && user.tipoUsuario === 0, [user]); // Modificado para checar o tipoUsuario

  const hasPermission = useCallback(
    (permission) => {
      switch (permission) {
        case 'admin':
          return isAdmin();
        default:
          return false;
      }
    },
    [isAdmin]
  );

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated, isAdmin, hasPermission }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}