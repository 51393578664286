import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Breadcrumb, Table, Button, Col, Modal, Input, Upload, Image, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AiOutlineEye } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient'
import Filtro from '../../Components/Filtro';
import moment from 'moment';
import 'moment/locale/pt-br';

const Saques = () => {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Adicionar Categoria');
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState('');
  const [valor, setValor] = useState('');
  const [create, setCreate] = useState('');
  const [dataPagamento, setDataPagamento] = useState('');
  const [produto, setProduto] = useState('');
  const [cpfcnpj, setCpfcnpj] = useState('');
  const [status, setStatus] = useState('');
  const [banco, setBanco] = useState('');
  const [contaDigito, setContaDigito] = useState('');
  const [agenciaDigito, setAgenciaDigito] = useState('');
  const [favorecido, setFavorecido] = useState('');
  const [email, setEmail] = useState('');
  const [codigo, setCodigo] = useState('');
  const [tipo, setTipo] = useState('');
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [modalAction, setModalAction] = useState('Adicionar');
  const [data, setData] = useState([]);

  const [dataRestaurante, setDataRestaurante] = useState([]);

  useEffect(() => {
    fetchDataSaques();
    fetchRestaurantDetails();
  }, []);

  const fetchDataSaques = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/saques');
      setData(response.data.message);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar as pagamentos!');
    }
    setLoading(false);
  };

  const fetchRestaurantDetails = async () => {
    try {
      const response = await api.get('/admin/restaurantes');
      
      if (Array.isArray(response.data.message)) {
        setDataRestaurante(response.data.message);
      } else {
        console.error('Data received is not an array:', response.data.message);
        message.error('Dados dos restaurantes não estão no formato esperado!');
      }
    } catch (error) {
      console.error('Erro ao buscar dados dos restaurantes:', error);
      message.error('Erro ao buscar os detalhes dos restaurantes!');
    }
  };
  
  const handleEdit = (record) => {
    setModalTitle('Saques');
    setModalAction('Pagar');
    setValor(record.valor);
    setTipo(record.tipo);
    setCreate(record.create);
    setDataPagamento(record.dataPagamento);
    setProduto(record.produto);
    setStatus(record.status);
    setCodigo(record.codigo);
  
    // Verificar se dataRestaurante é um array
    if (Array.isArray(dataRestaurante)) {
      const restaurantData = dataRestaurante.find(
        (restaurant) => restaurant.id === record.restauranteId
      );
  
      if (restaurantData) {
        // Verificar se dados_banco é um array e tem pelo menos um item
        if (Array.isArray(restaurantData.dados_banco) && restaurantData.dados_banco.length > 0) {
          const bankData = restaurantData.dados_banco[0]; // Pega o primeiro item do array
  
          setFavorecido(bankData.favorecido || ''); // Atualize conforme o campo real
          setBanco(bankData.nomeBanco || ''); // Atualize conforme o campo real
          setContaDigito(bankData.conta || ''); // Atualize conforme o campo real
          setAgenciaDigito(bankData.agencia + (bankData.agenciaDg ? '-' + bankData.agenciaDg : '')); // Atualize conforme o campo real
        } else {
          message.error('Dados bancários não encontrados!');
          setFavorecido(''); // Limpa o campo se não encontrar
          setBanco(''); // Limpa o campo se não encontrar
          setContaDigito(''); // Limpa o campo se não encontrar
          setAgenciaDigito(''); // Limpa o campo se não encontrar
        }
      } else {
        message.error('Restaurante não encontrado!');
        setFavorecido(''); // Limpa o campo se não encontrar
        setBanco(''); // Limpa o campo se não encontrar
        setContaDigito(''); // Limpa o campo se não encontrar
        setAgenciaDigito(''); // Limpa o campo se não encontrar
      }
    } else {
      message.error('Dados de restaurante não estão disponíveis!');
      setFavorecido(''); // Limpa o campo se os dados não estiverem disponíveis
      setBanco(''); // Limpa o campo se os dados não estiverem disponíveis
      setContaDigito(''); // Limpa o campo se os dados não estiverem disponíveis
      setAgenciaDigito(''); // Limpa o campo se os dados não estiverem disponíveis
    }
  
    setOpen(true);
    showLoading();
  };
  
  

  const filtroOptions = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
  ];

  const showLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleFilter = (filters) => {
    console.log('Filtrando com:', filters);
    // Implementar a lógica para filtrar os dados da tabela
  };

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const valorFormatado = formatarValor(valor)

  const columns = [

    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
    },
    {
      title: 'Usuário',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => (
        <div>
          <a>{text}</a>
          <div style={{ fontSize: '12px' }}>{record.userEmail}</div>
        </div>
      ),
      width: 150,
    },
    {
      title: 'Restaurante',
      dataIndex: 'restaurantName',
      key: 'restaurantName',
      render: (text, record) => (
        <div>
          <a>{text}</a>
          <div style={{ fontSize: '12px' }}>{record.restaurantCNPJ}</div>
        </div>
      ),
      width: 80,

    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      render: (text) => formatarValor(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (address, record) => (
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<AiOutlineEye />}
            size={10}
            style={{ marginRight: '8px' }}
            onClick={() => handleEdit(record)}
          />
        </Col>
      ),
    },
  ];

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Comprovante</div>
    </button>
  );

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <a>Gerenciamento</a>,
          },
          {
            title: (
              <>
                <span>Saques</span>
              </>
            ),
          },
        ]}
      />


      <Filtro
        onFilter={handleFilter}
        options={filtroOptions}
        showUsuarioInput={true}
        showTipoSelect={true}
        showButtonFiltrar={true}
      />

      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
      />

      <Modal
        title={<p>{modalTitle}</p>}
        footer={
          <div>
            <Button type="secondary" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={() => setOpen(false)}>
              {modalAction}
            </Button>
          </div>
        }
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <div className={styles.containerModal}>
        <span className={styles.codigo}>{codigo}</span>
          <span className={`${styles.status} ${status === 'Pago' ? styles.statusAtivo : styles.statusPendente}`}>{status}</span>
          <div>
            <label>Favorecido</label>
            <Input
              value={favorecido}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Valor</label>
            <Input
              value={valorFormatado}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Banco</label>
            <Input
              value={banco}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Conta</label>
            <Input
              value={contaDigito}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Agencia</label>
            <Input
              value={agenciaDigito}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Tipo</label>
            <Input
              value={tipo}
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles.containerUpload}>
            <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture-circle"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>

            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Saques;
