// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__zpRNW {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}

.style_containerModal__vCQru {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style_containerUpload__9VRsh {
  display: flex;
  justify-content: center;
}

.style_row__0zNol {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

.style_col__4TpK\\+ {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_filters__FBSiq {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 0.5rem;
  padding: 24px 0px;
  border-radius: 8px;
}

.style_inputsContainer__HH7nP {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.style_inputWrapper__Dc72u, .style_selectWrapper__HDj-U {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Restaurantes/style.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;AACF;;AAEA;EACE,SAAA;AACF","sourcesContent":[".container {\n  width: 90%;\n  min-height: 100%;\n  margin: auto;\n  padding: 24px 0px;\n}\n\n.containerModal {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.containerUpload {\n  display: flex;\n  justify-content: center;\n}\n\n.row {\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n}\n\n.col {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.filters {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  margin-bottom: .5rem;\n  padding: 24px 0px;\n  border-radius: 8px;\n}\n\n.inputsContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n}\n\n.inputWrapper, .selectWrapper {\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__zpRNW`,
	"containerModal": `style_containerModal__vCQru`,
	"containerUpload": `style_containerUpload__9VRsh`,
	"row": `style_row__0zNol`,
	"col": `style_col__4TpK+`,
	"filters": `style_filters__FBSiq`,
	"inputsContainer": `style_inputsContainer__HH7nP`,
	"inputWrapper": `style_inputWrapper__Dc72u`,
	"selectWrapper": `style_selectWrapper__HDj-U`
};
export default ___CSS_LOADER_EXPORT___;
