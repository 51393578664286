import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import { AiOutlineSearch, AiOutlineFunnelPlot } from "react-icons/ai";
import styles from './styles.module.scss';

const { Option } = Select;

const Filtro = ({ onFilter, options, showUsuarioInput = true, showTipoSelect = true }) => {
    const [titulo, setTitulo] = useState('');
    const [tipo, setTipo] = useState(null);

    const handleFilter = () => {
        onFilter({ usuario, tipo });
    };

    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    return (
        <div className={styles.filtro}>
            <div className={styles.inputsContainer}>
                {showUsuarioInput && (
                    <div className={styles.inputWrapper}>
                        <Input
                        placeholder="Pesquisar..."
                            prefix={<AiOutlineSearch />}
                            className={styles.input}
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                )}
                {showTipoSelect && (
                    <div className={styles.selectWrapper}>
                        <Select
                            className={styles.select}
                            value={tipo || undefined}
                            placeholder="Selecione..."
                            onChange={(value) => setTipo(value)}
                            style={{ width: '100%' }}
                        >
                            {options.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Filtro;
