import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Select, Upload, Image, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import CategoriaModal from '../../Components/CategoriaModal';
import { AiOutlineDelete } from "react-icons/ai";
import { useAuth } from '../../contexts/AuthContext';

const ProdutoRestauranteModal = ({
    open,
    setOpen,
    modalTitle,
    modalAction,
    selectedProduct,
    loading,
    setLoading,
    previewImage,
    setPreviewImage,
    previewOpen,
    setPreviewOpen,
    fecthDataProduto,
    editRecordId,
}) => {
    const { user } = useAuth();
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [tempoPreparacao, setTempoPreparacao] = useState('');
    const [valor, setValor] = useState('');
    const [sku, setSku] = useState('');
    const [status, setStatus] = useState(null);
    const [fileListPrincipal, setFileListPrincipal] = useState([]);
    const [fileListFotos, setFileListFotos] = useState([]);
    const [fileListVideos, setFileListVideos] = useState([]);
    const [imageUrlsPrincipal, setImageUrlsPrincipal] = useState([]);
    const [imageUrlsFotos, setImageUrlsFotos] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [imagemPrincipal, setImagemPrincipal] = useState('');
    const [imagemFotos, setImagemFotos] = useState('');
    const [video, setVideo] = useState('');
    const [categoriaId, setCategoriaId] = useState('');
    const [categoriaList, setCategoriaList] = useState([]);
    const [modalActionCategoria, setModalActionCategoria] = useState('Adicionar');
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [categoriaModalVisible, setCategoriaModalVisible] = useState(false);
    const [adicionais, setAdicionais] = useState([{ id: null, titulo: '', valor: '' }]);
    const [adicionalModalOpen, setAdicionalModalOpen] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchDataCategoria();
    }, []);

    const handleFormSubmitCategoria = () => {
        fetchDataCategoria();
        setCategoriaModalVisible(false);
    };

    const openAddCategoriaModal = () => {
        setModalActionCategoria('Adicionar');
        setSelectedCategoria(null);
        setCategoriaModalVisible(true);
    };

    useEffect(() => {
        if (selectedProduct) {
            setTitulo(selectedProduct.titulo);
            setDescricao(selectedProduct.descricao);
            setTempoPreparacao(selectedProduct.tempoPreparacao);
            setValor(selectedProduct.valor);
            setSku(selectedProduct.sku);
            setStatus(selectedProduct.status);
            setCategoriaId(selectedProduct.categorias.id)
            setAdicionais(selectedProduct.adicionais.map(adicional => ({
                titulo: adicional.titulo,
                valor: adicional.valor,
            })));

        } else {
            setTitulo('');
            setDescricao('');
            setTempoPreparacao('');
            setValor('');
            setSku('');
            setStatus(null);
            setAdicionais([{ titulo: '', valor: '' }]);
        }
    }, [selectedProduct]);

    const handleSubmitAndFormSubmit = async () => {
        if (!titulo || !tempoPreparacao || !status || !valor) {
            message.error('Por favor, preencha todos os campos!');
            return;
        }
    
        setLoading(true);
    
        try {
            const urlsFotosPrincipal = await Promise.all(
                fileListPrincipal.map(async (file) => {
                    if (!file.url && !file.response) {
                        const response = await handleUploadPrincipal({ file: file.originFileObj });
                        return response;
                    }
                    return file.url || file.response;
                })
            );
    
            const urlsFotos = await Promise.all(
                fileListFotos.map(async (file) => {
                    if (!file.url && !file.response) {
                        const response = await handleUploadFotos({ file: file.originFileObj });
                        return response;
                    }
                    return file.url || file.response;
                })
            );
    
            const urlsVideo = await Promise.all(
                fileListVideos.map(async (file) => {
                    if (!file.url && !file.response) {
                        const response = await handleUploadVideo({ file: file.originFileObj });
                        return response;
                    }
                    return file.url || file.response;
                })
            );
    
            // Função para converter o valor monetário em número
            const parseCurrency = (value) => {
                if (typeof value === 'string') {
                    return Number(value.replace(/[^\d,.-]/g, '').replace(',', '.'));
                }
                return value;
            };
    
            // Convertendo o valor do produto e dos adicionais para número
            const valorNumerico = parseCurrency(valor);
            const adicionaisFormatados = adicionais.map((adicional) => ({
                titulo: adicional.titulo,
                valor: parseCurrency(adicional.valor),
            }));
    
            const authToken = localStorage.getItem('RangoPassToken');
            const dadosProdutos = {
                restauranteId: user.restaurante.id,
                categoriaId,
                titulo,
                descricao,
                tempoPreparacao: Number(tempoPreparacao),
                status,
                valor: valorNumerico, // Valor do produto convertido
                sku,
                imgPrincipal: imagemPrincipal,
                imgInterna: imagemFotos,
                video: video,
                adicionais: adicionaisFormatados, // Adicionais com valor numérico
            };
    
            let response;
    
            if (modalAction === 'Cadastrar') {
                response = await api.post('/restaurante/criar-produto', dadosProdutos, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                message.success('Produto cadastrado com sucesso!');
            } else if (modalAction === 'Salvar') {
                response = await api.put(`/restaurante/editar-produto/${editRecordId}`, dadosProdutos, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                message.success('Produto atualizado com sucesso!');
            }
    
            fecthDataProduto();
            setOpen(false);
        } catch (error) {
            console.error(error.message);
            message.error('Erro ao cadastrar/atualizar produto!');
        }
    
        setLoading(false);
    };
    

    const fetchDataCategoria = async () => {
        setLoading(true);
        try {
            const response = await api.get('/categorias');

            const filteredCategorias = response.data.message.filter(categoria => categoria.tipo === 'produto');

            setCategoriaList(filteredCategorias);
        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            message.error('Erro ao buscar as categorias!');
        } finally {
            setLoading(false);
        }
    };

    const uploadButton = (label) => (
        <button style={{ border: 0, background: 'none' }} type="button">
            <div>{label}</div>
        </button>
    );

    const statusOptions = [
        { value: 'ativo', label: 'Ativo' },
        { value: 'inativo', label: 'Inativo' },
    ];

    const onPreviewPrincipal = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onPreviewFotos = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onPreviewVideo = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const video = new Video();
        video.src = src;
        const videoWindow = window.open(src);
        videoWindow?.document.write(video.outerHTML);
    };

    const onChangePrincipal = ({ fileList: newFileList }) => {
        const validFileList = newFileList.filter(file => isFileSizeValid(file.originFileObj));
        setFileListPrincipal(validFileList);
        const urls = validFileList.map(file => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
        setImageUrlsPrincipal(urls);
    };

    const onChangeFotos = ({ fileList: newFileList }) => {
        const validFileList = newFileList.filter(file => isFileSizeValid(file.originFileObj));
        setFileListFotos(validFileList);
        const urlsFotos = validFileList.map(file => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
        setImageUrlsFotos(urlsFotos);
    };

    const onChangeVideo = ({ fileList: newFileList }) => {
        setFileListVideos(newFileList);
        const urlsVideo = newFileList.map(file => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
        setVideoUrls(urlsVideo);
        console.log('urls', urlsVideo);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

    const isFileSizeValid = (file) => {
        return file.size <= MAX_FILE_SIZE;
    };


    const handleUploadPrincipal = async ({ file, onSuccess, onError }) => {

        if (!isFileSizeValid(file)) {
            message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
            if (onError) {
                onError(new Error('O arquivo é muito grande.'));
            }
            return;
        }

        const formData = new FormData();
        formData.append('media', file);
        formData.append('type', 'imagem');

        try {
            const response = await api.post('/upload-media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onSuccess) {
                onSuccess(response.data.message.key);
                setImagemPrincipal(response.data.message.key);
            }
            return response.data.message.key;
        } catch (error) {
            if (onError) {
                onError(error);
            }
            console.error('Erro ao fazer upload da imagem:', error.message);
        }
    };

    const handleUploadFotos = async ({ file, onSuccess, onError }) => {
        if (!isFileSizeValid(file)) {
            message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
            if (onError) {
                onError(new Error('O arquivo é muito grande.'));
            }
            return;
        }

        const formData = new FormData();
        formData.append('media', file);
        formData.append('type', 'imagem');

        try {
            const response = await api.post('/upload-media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onSuccess) {
                onSuccess(response.data.message.key);
                setImagemFotos(response.data.message.key);
            }
            return response.data.message.key;
        } catch (error) {
            if (onError) {
                onError(error);
            }
            console.error('Erro ao fazer upload da imagem:', error.message);
        }
    };


    const handleUploadVideo = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('media', file);
        formData.append('type', 'video');

        try {
            const response = await api.post('/upload-media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onSuccess) {
                onSuccess(response.data.message.key);
                console.log(response.data.message.url);
                setVideo(response.data.message.key);
                console.log('response.data.message.key', response.data.message.key);
            }
            return response.data.message.key;
        } catch (error) {
            if (onError) {
                onError(error);
            }
            console.error('Erro ao fazer upload do vídeo:', error.message);
        }
    };

    const handleSaveAdicional = () => {
        setAdicionalModalOpen(false);
    };

    const handleAddRow = () => {
        setAdicionais([...adicionais, { id: null, titulo: '', valor: '' }]);
    };

    const handleAdicionalChange = (index, field, value) => {
        const newAdicionais = [...adicionais];
        newAdicionais[index][field] = value;
        setAdicionais(newAdicionais);
    };

    const handleDeleteRow = (index) => {
        const newAdicionais = adicionais.map((adicional, i) =>
            i === index ? { ...adicional, desativado: true } : adicional
        );
        setAdicionais(newAdicionais);
        console.log('newAdicionais', newAdicionais);
    };

    const formatCurrency = (value) => {
        const number = value.replace(/\D/g, '');
        const integerPart = number.slice(0, -2) || '0';
        const decimalPart = number.slice(-2).padEnd(0, '0');
        const formattedIntegerPart = integerPart.replace(/^0+/, '') || '0';
        const integerWithThousandSeparator = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `R$ ${integerWithThousandSeparator},${decimalPart}`;
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
        setValor(formatCurrency(numericValue));
    };

    return (
        <div>
            <Modal
                title={<p>{modalTitle}</p>}
                footer={
                    <div className={styles.buttons}>
                        <Button type="primary" onClick={() => setAdicionalModalOpen(true)}>
                            Adicionais
                        </Button>
                        <Button type="secondary" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button type="primary" onClick={handleSubmitAndFormSubmit}>
                            {modalAction}
                        </Button>
                    </div>
                }
                loading={loading}
                open={open}
                onCancel={() => setOpen(false)}
                confirmLoading={loading}
            >
                <div className={styles.containerModal}>
                    <div className={styles.containerUpload}>
                        <ImgCrop rotationSlider>
                            <Upload
                                listType="picture-card"
                                fileList={fileListPrincipal}
                                onChange={onChangePrincipal}
                                onPreview={onPreviewPrincipal}
                                customRequest={handleUploadPrincipal}
                            >
                                {fileListPrincipal.length < 1 && 'Foto Principal'}
                            </Upload>
                        </ImgCrop>
                    </div>

                    <div className={styles.containerModal}>

                        <div>
                            <label>Produto</label>
                            <Input
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div>
                            <label>Descrição</label>
                            <Input
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.row}>
                            <div>
                                <label>Tempo Preparo</label>
                                <Input
                                    type='number'
                                    value={tempoPreparacao}
                                    onChange={(e) => setTempoPreparacao(e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <label>Valor</label>
                                <Input
                                    type='text'
                                    value={valor}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Código SKU</label>
                            <Input
                                value={sku}
                                onChange={(e) => setSku(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.col}>
                            <label>Categoria</label>
                            <Select className={styles.select} value={categoriaId} placeholder='Selecione...' onChange={(value) => setCategoriaId(value)} style={{ width: '100%' }} showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }>
                                {categoriaList.map((categoria) => (
                                    <Select.Option key={categoria.id} value={categoria.value}>
                                        {categoria.titulo}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        {/* <div >
                            <Button onClick={openAddCategoriaModal}>+ Categoria</Button>
                        </div> */}
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <label>Status</label>
                                <Select
                                    className={styles.select}
                                    value={status}
                                    placeholder="Selecione..."
                                    onChange={(value) => setStatus(value)}
                                    style={{ width: '100%' }}
                                >
                                    {statusOptions.map((status) => (
                                        <Select.Option key={status.value} value={status.value}>
                                            {status.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className={styles.containerUpload}>
                            <ImgCrop rotationSlider>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileListFotos}
                                    onChange={onChangeFotos}
                                    onPreview={onPreviewFotos}
                                    customRequest={handleUploadFotos}
                                >
                                    {fileListFotos.length < 3 && 'Fotos'}
                                </Upload>
                            </ImgCrop>
                            <Upload
                                listType="picture-card"
                                fileList={fileListVideos}
                                onPreview={onPreviewVideo}
                                onChange={onChangeVideo}
                                customRequest={handleUploadVideo}
                            >
                                {fileListVideos.length >= 1 ? null : uploadButton('Vídeos')}
                            </Upload>

                            {previewImage && (
                                <Image
                                    wrapperStyle={{ display: 'none' }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <CategoriaModal
                visible={categoriaModalVisible}
                onClose={() => setCategoriaModalVisible(false)}
                onAddCategoria={() => fetchDataCategoria()}
                onSubmit={handleFormSubmitCategoria}
                modalAction={modalActionCategoria}
                selectedCategoria={selectedCategoria}
                initialData={data.find((item) => item.id === editRecordId) || {}}
            />
            <Modal
                title={<p>Adicionais</p>}
                footer={
                    <div className={styles.buttons}>
                        <Button type="primary" onClick={handleAddRow}>
                            Add
                        </Button>
                        <Button type="primary" onClick={handleSaveAdicional}>
                            Salvar
                        </Button>
                        <Button type="secondary" onClick={() => setAdicionalModalOpen(false)}>
                            Cancelar
                        </Button>
                    </div>
                }
                open={adicionalModalOpen}
                onCancel={() => setAdicionalModalOpen(false)}
            >
                {adicionais
                    .filter(adicional => !adicional.desativado) // Filtra itens onde desativado é false ou não está presente
                    .map((adicional, index) => (
                        <div key={index} className={styles.adicionais}>
                            <div className={styles.containerAdicionais}>
                                <label>Titulo</label>
                                <Input
                                    value={adicional.titulo}
                                    onChange={(e) => handleAdicionalChange(index, 'titulo', e.target.value)}
                                    style={{ marginRight: 8 }}
                                />
                            </div>
                            <div className={styles.containerAdicionais}>
                                <label>Valor</label>
                                <Input
                                    type='number'
                                    value={adicional.valor}
                                    onChange={(e) => handleAdicionalChange(index, 'valor', e.target.value)}
                                    style={{ width: '100%' }}
                                    prefix='R$ '
                                />
                            </div>
                            <div className={styles.iconAdicionais}>
                                <Button shape="circle" onClick={() => handleDeleteRow(index)}>
                                    <AiOutlineDelete />
                                </Button>
                            </div>
                        </div>
                    ))}
            </Modal>
        </div>
    );
};

export default ProdutoRestauranteModal;
