// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_filtro__wDDiZ {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding: 24px 0px;
  border-radius: 8px;
}

.styles_inputsContainer__EQuYc {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
}

.styles_inputWrapper__uSdja, .styles_selectWrapper__mpbcZ {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/Filtro/styles.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,aAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;AADJ;;AAIA;EACI,SAAA;AADJ","sourcesContent":["@import '../../cores.scss';\n// styles.module.scss\n.filtro {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: .5rem;\n    padding: 24px 0px;\n    border-radius: 8px;\n}\n\n.inputsContainer {\n    display: flex;\n    gap: 16px;\n    width: 100%;\n    justify-content: center;\n}\n\n.inputWrapper, .selectWrapper {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtro": `styles_filtro__wDDiZ`,
	"inputsContainer": `styles_inputsContainer__EQuYc`,
	"inputWrapper": `styles_inputWrapper__uSdja`,
	"selectWrapper": `styles_selectWrapper__mpbcZ`
};
export default ___CSS_LOADER_EXPORT___;
