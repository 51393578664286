import React, { useState } from 'react';
import { Layout, Menu, Avatar, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/logo.png';

import styles from './style.module.scss';
import { useMenuData } from '../../menuData'; // Verifique o caminho correto

const { Sider } = Layout;

const SidebarOfficial = () => {
  const { user, logout } = useAuth();
  const menuData = useMenuData(); // Hook para obter dados do menu

  const [collapsed, setCollapsed] = useState(false); // Estado para controlar se a sidebar está recolhida ou não

  const toggleCollapsed = () => {
    setCollapsed(!collapsed); // Inverte o estado de collapsed
  };

  const renderMenuItem = (item) => (
    <Menu.Item key={item.key} icon={item.icon} className={styles.leftAlign}>
      {item.to ? <Link to={item.to}>{item.title}</Link> : <p style={{ color: '#000' }}>{item.title}</p>}
    </Menu.Item>
  );

  const renderSubMenu = (submenu) => (
    <Menu.SubMenu key={submenu.key} icon={submenu.icon} title={submenu.title} className={styles.leftAlign}>
      {submenu.children.map((child) => renderMenuItem(child))}
    </Menu.SubMenu>
  );

  const renderMenuItems = () =>
    menuData.flatMap((item, index) => {
      const menuItem = item.children ? renderSubMenu(item) : renderMenuItem(item);
      const divider = item.hasDividerBefore ? <Divider key={`divider-${index}`} /> : null;
      return divider ? [divider, menuItem] : menuItem;
    });

  return (
    <Sider width={256} collapsible collapsed={collapsed} onCollapse={toggleCollapsed} breakpoint='md' collapsedWidth='0' theme='light' className={styles.sidebar}>
      <div className={styles.logosidebar}>
        <img src={logo} alt='Logo' style={{ width: '80%', height: 'auto' }} />
      </div>

      <Menu inlineCollapsed={true} theme='light' mode='inline' defaultSelectedKeys={['dashboard']} style={{ minHeight: 'auto', overflowX: 'auto', borderRight: 0, backgroundColor: '#FFFFFF' }}>
        {renderMenuItems()}
      </Menu>

      <div className={styles.sidebarFooter}>
        <Link to='/login'>
          <Button
            icon={
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path
                  d='M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V9H5V5H19V19H5V15H3V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
                  fill='#EB4143'
                />
              </svg>
            }
            type='text'
            onClick={logout}
            className={styles.button}
          >
            Sair
          </Button>
        </Link>
      </div>
    </Sider>
  );
};

export default SidebarOfficial;
