import React from 'react';
import { Input } from 'antd';
import InputMask from 'react-input-mask';

const TimeInput = ({ value, onChange }) => {
  return (
    <div>
      <InputMask
        mask="99:99"
        value={value}
        onChange={onChange}
        placeholder="hh:mm"
        maskChar={null}
      >
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
    </div>
  );
};

export default TimeInput;
