import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Modal, Input, Select, Button, message } from 'antd';
import { api } from '../../services/apiClient'; 

const CategoriaModal = ({
  visible,
  onClose,
  onSubmit,
  modalAction,
  initialData = {},
}) => {
  const [titulo, setTitulo] = useState(initialData.titulo || '');
  const [descricao, setDescricao] = useState(initialData.descricao || '');
  const [tipo, setTipo] = useState(initialData.tipo || null);
  const [status, setStatus] = useState(initialData.status || null);

  useEffect(() => {
    setTitulo(initialData.titulo || '');
    setDescricao(initialData.descricao || '');
    setTipo(initialData.tipo || null);
    setStatus(initialData.status || null);
  }, [initialData]);

  const handleFormSubmit = async () => {
    if (!titulo || !status || !tipo) {
      message.error('Por favor, preencha todos os campos!');
      return;
    }

    try {
      const authToken = localStorage.getItem('RangoPassToken');
      const dadosCategoria = { titulo, status, descricao, tipo };

      let response;
      if (modalAction === 'Adicionar') {
        response = await api.post('/categoria/criar', dadosCategoria, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        message.success('Categoria cadastrada com sucesso!');
      } else if (modalAction === 'Salvar') {
        response = await api.put(`/categorias/editar/${initialData.id}`, dadosCategoria, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        message.success('Categoria atualizada com sucesso!');
      }

      onSubmit(); // Callback para atualizar a lista
      onClose(); // Fecha o modal
    } catch (error) {
      console.error(error.message);
      message.error('Erro ao cadastrar/atualizar categoria!');
    }
  };

  return (
    <Modal
      title={modalAction === 'Adicionar' ? 'Adicionar Categoria' : 'Editar Categoria'}
      open={visible}
      footer={
        <div>
          <Button type="primary" onClick={handleFormSubmit}>
            {modalAction}
          </Button>
          <Button type="secondary" onClick={onClose}>
            Cancelar
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      <div className={styles.containerModal}>
        <div>
          <label>Categoria</label>
          <Input
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            placeholder="Digite o título..."
            style={{ width: '100%' }}
          />
        </div>
        <div>
          <label>Descrição</label>
          <Input
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            placeholder="Digite a descrição..."
            style={{ width: '100%' }}
          />
        </div>
        <div>
          <label>Tipo</label>
          <Select
            className={styles.select}
            value={tipo}
            placeholder="Selecione..."
            onChange={(value) => setTipo(value)}
            style={{ width: '100%' }}
          >
            <Select.Option value="restaurante">Restaurante</Select.Option>
            <Select.Option value="produto">Produto</Select.Option>
          </Select>
        </div>
        <div>
          <label>Status</label>
          <Select
            className={styles.select}
            value={status}
            placeholder="Selecione..."
            onChange={(value) => setStatus(value)}
            style={{ width: '100%' }}
          >
            <Select.Option value="ativo">Ativo</Select.Option>
            <Select.Option value="inativo">Inativo</Select.Option>
            <Select.Option value="deletado">Deletado</Select.Option>
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default CategoriaModal;
