// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__PC3yw {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Checkins/style.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".container {\n  width: 90%;\n  min-height: 100%;\n  margin: auto;\n  padding: 24px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__PC3yw`
};
export default ___CSS_LOADER_EXPORT___;
