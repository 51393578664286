// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Nvkqc {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}

.style_containerModal__aozij {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style_containerUpload__LLaPG {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Notificacao/style.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".container {\n    width: 90%;\n    min-height: 100%;\n    margin: auto;\n    padding: 24px 0px;\n  }\n  \n  .containerModal {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n  \n  .containerUpload {\n    display: flex;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Nvkqc`,
	"containerModal": `style_containerModal__aozij`,
	"containerUpload": `style_containerUpload__LLaPG`
};
export default ___CSS_LOADER_EXPORT___;
