import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarOfficial from './Components/Sidebar';
import Dashboard from './Pages/Dashboard';
import './index.scss';
import { Layout } from 'antd';
import Pagamentos from './Pages/Pagamentos';
import Saques from './Pages/Saques';
import Checkins from './Pages/Checkins';
import Pedidos from './Pages/Pedidos';
import Restaurantes from './Pages/Restaurantes';
import Produtos from './Pages/Produtos';
import Categorias from './Pages/Categorias';
import Usuarios from './Pages/Usuarios';
import Cupons from './Pages/Cupons';
import TipoRelacionamento from './Pages/TipoRelacionamento';
import DadosRestaurante from './Pages/DadosRestaurante';
import Notificacao from './Pages/Notificacao';
import Login from './Pages/Login';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, useAuth } from './contexts/AuthContext';

const { Header, Footer, Sider, Content } = Layout;

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <AuthProvider>
        {isAuthenticated() ? (
          <Layout style={{ minHeight: '100vh' }}>
            <SidebarOfficial />
            <Layout>
              <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/pagamentos' element={<Pagamentos />} />
                <Route path='/saques' element={<Saques />} />
                <Route path='/checkins' element={<Checkins />} />
                <Route path='/pedidos' element={<Pedidos />} />
                <Route path='/restaurantes' element={<Restaurantes />} />
                <Route path='/produtos' element={<Produtos />} />
                <Route path='/categorias' element={<Categorias />} />
                <Route path='/usuarios' element={<Usuarios />} />
                <Route path='/cupons' element={<Cupons />} />
                <Route path='/tiporecebimento' element={<TipoRelacionamento />} />
                <Route path='/notificacao' element={<Notificacao />} />
                <Route path='/dados-restaurante/:id' element={<DadosRestaurante />} />
              </Routes>
              {/* <ToastContainer /> */}
            </Layout>
          </Layout>
        ) : (
          <Login />
        )}
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;
