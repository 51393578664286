// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_containerRestaurante__jInIc {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: start;
}

.style_imagemRestaurante__kjMeV img {
  width: 60px;
  height: auto;
  border-radius: 50%;
}

.style_descricao__uXQ0w {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_titulo__S-03q {
  white-space: nowrap;
  font-weight: 600;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_texto__1iN3K {
  align-items: start;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/Components/RestauranteTable/style.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAEA;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ","sourcesContent":["\n.containerRestaurante {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n    justify-content: start;\n}\n\n.imagemRestaurante img {\n    width: 60px;\n    height: auto;\n    border-radius: 50%; // Define a borda arredondada. Use 50% para um círculo.\n}\n.descricao {\n    font-size: 12px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.titulo {\n    white-space: nowrap;\n    font-weight: 600;\n    font-size: 1rem;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.texto {\n    align-items: start;\n    text-align: start;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRestaurante": `style_containerRestaurante__jInIc`,
	"imagemRestaurante": `style_imagemRestaurante__kjMeV`,
	"descricao": `style_descricao__uXQ0w`,
	"titulo": `style_titulo__S-03q`,
	"texto": `style_texto__1iN3K`
};
export default ___CSS_LOADER_EXPORT___;
