import React from 'react';
import { Button, Col, Table, Popconfirm  } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import moment from 'moment';

const formatarTelefone = (telefone) => {
    const numeros = telefone.replace(/\D/g, '');
    if (numeros.length <= 10) {
        return numeros.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
    } else {
        return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
};


const formatarDocumento = (documento) => {
    const apenasNumeros = documento.replace(/\D/g, '');
    if (apenasNumeros.length === 11) {
        return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (apenasNumeros.length === 14) {
        return apenasNumeros.replace(/(\d{2})(\d{4})(\d{4})(\d{2})/, '$1.$2.$3/$4-');
    }
    return documento;
};

const RestauranteTable = ({ data, handleDelete }) => {
    const columns = [
        {
            title: 'Restaurante',
            dataIndex: 'nome',
            key: 'nome',
            render: (text, record) => {
                const imageUrl = record.arquivos && record.arquivos[0] ? `https://rangopassbucket.s3.amazonaws.com/${record.arquivos[0].key}` : '';
                return (
                    <div className={styles.containerRestaurante}>
                        <div className={styles.imagemRestaurante}>
                            {imageUrl ? <img src={imageUrl} alt={text} /> : 'Imagem não disponível'}
                        </div>
                        <div className={styles.texto}>
                            <a className={styles.titulo}>{text}</a>
                            <div className={styles.descricao}>{formatarDocumento(record.valorDoc)}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Telefone',
            dataIndex: 'telefone',
            key: 'telefone',
            render: (text) => formatarTelefone(text),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: {
                showTitle: false,
            },
            render: (status) => {
                switch (status) {
                    case 'ativo':
                        return 'Ativo';
                    case 'inativo':
                        return 'Inativo';
                    case 'deletado':
                        return 'Deletado';
                    default:
                        return 'Desconhecido';
                }
            },
        },
        {
            title: 'Cadastrado em',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
            ellipsis: {
                showTitle: false,
            },
            render: (address, record) => (
                <Col>
                    <Link to={`/dados-restaurante/${record.id}`}>
                        <Button type='primary' shape='circle' icon={<AiOutlineEdit />} size={10} style={{ marginRight: '8px' }} />
                    </Link>

                    <Popconfirm
                        title="Tem certeza que deseja excluir este restaurante?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            type='primary'
                            shape='circle'
                            icon={<AiOutlineDelete />}
                            size={10}
                        />
                    </Popconfirm>
                </Col>
            ),
        },
    ];

    return (
        <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />
    );
};

export default RestauranteTable;
