import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Breadcrumb, Button, Image, message, Input, Select } from 'antd';
import { IoAdd } from 'react-icons/io5';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient';
import moment from 'moment';
import CategoriaModal from '../../Components/CategoriaModal';
import RestauranteModal from '../../Components/RestauranteModal';
import RestauranteTable from '../../Components/RestauranteTable';
import 'moment/locale/pt-br';

const Restaurante = () => {
  const [categoriaModalVisible, setCategoriaModalVisible] = useState(false);
  const [modalActionCategoria, setModalActionCategoria] = useState('Adicionar');
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [editRecordId, setEditRecordId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [proprietario, setProprietario] = useState(null);
  const [nomeRestaurante, setNomeRestaurante] = useState('');
  const [nomeRestauranteFantasia, setNomeRestauranteFantasia] = useState('');
  const [emailRestaurante, setEmailRestaurante] = useState('');
  const [docRestaurante, setDocRestaurante] = useState('');
  const [telefoneRestaurante, setTelefoneRestaurante] = useState('');
  const [categoriaRestaurante, setCategoriaRestaurante] = useState('');
  const [recebimentoRestaurante, setRecebimentoRestaurante] = useState('');
  const [favorecidoRestaurante, setFavorecidoRestaurante] = useState('');
  const [cpfcnpjFavorecido, setCpfcnpjFavorecido] = useState('');
  const [editRecordIdRestaurante, setEditRecordIdRestaurante] = useState(null);
  const [cepRestaurante, setCepRestaurante] = useState('');
  const [logradouroRestaurante, setLogradouroRestaurante] = useState('');
  const [numeroRestaurante, setNumeroRestaurante] = useState('');
  const [complementoRestaurante, setComplementoRestaurante] = useState('');
  const [bairroRestaurante, setBairroRestaurante] = useState('');
  const [cidadeRestaurante, setCidadeRestaurante] = useState('');
  const [estadoRestaurante, setEstadoRestaurante] = useState('');
  const [statusRestaurante, setStatusRestaurante] = useState('ativo');
  const [tipoDocRestaurante, setTipoDocRestaurante] = useState('cnpj');
  const [aberto, setAberto] = useState(false);
  const [banco, setBanco] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [agencia, setAgencia] = useState('');
  const [digito, setDigito] = useState('');
  const [conta, setConta] = useState('');
  const [chavePix, setChavePix] = useState('');
  const [modalAction, setModalAction] = useState('Cadastrar');
  const [imagemRestaurante, setImagemRestaurante] = useState('');
  const [fileList, setFileList] = useState([]);
  const [restauranteModalOpen, setRestauranteModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [categoriaList, setCategoriaList] = useState([]);
  const [recebimentoList, setRecebimentoList] = useState([]);
  const [bancoList, setBancoList] = useState([]);
  const [proprietarioList, setProprietarioList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    nome: '',
    status: '',
  });


  useEffect(() => {
    fetchDataRestaurante();
    fetchDataCategoria();
    handleCepChange();
    fetchBancos();
    fetchDataTipoRecebimento();
    fetchDataUsuarios();
  }, []);

  const openAddCategoriaModal = () => {
    setModalActionCategoria('Adicionar');
    setSelectedCategoria(null);
    setCategoriaModalVisible(true);
  };

  const onChange = ({ fileList: newFileList }) => {
    // Filtra arquivos inválidos
    const validFileList = newFileList.filter(file => {
      // Confirma se o arquivo é um objeto de arquivo (originFileObj é definido)
      if (file.originFileObj) {
        return isFileSizeValid(file.originFileObj);
      }
      return true;
    });
  
    setFileList(validFileList);
  
    const urls = validFileList.map(file => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
    setImageUrls(urls);
  };
  
  
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const showLoading = () => {
    setRestauranteModalOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const fetchDataRestaurante = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/restaurantes');
      console.log('response', response)
      setData(response.data.message);
      setFilteredData(response.data.message);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar os restaurantes!');
    }
    setLoading(false);
  };

  const fetchDataCategoria = async () => {
    setLoading(true);
    try {
        const response = await api.get('/categorias');
        console.log('response', response);
        
        const filteredCategorias = response.data.message.filter(categoria => categoria.tipo === 'restaurante');

        setCategoriaList(filteredCategorias);
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        message.error('Erro ao buscar as categorias!');
    } finally {
        setLoading(false);
    }
};

  const fetchDataTipoRecebimento = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/tipo_recebimento');
      setRecebimentoList(response.data.message);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar tipos de recebimento!');
    }
    setLoading(false);
  };

  const fetchDataUsuarios = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/usuarios');
      setProprietarioList(
        response.data.map((usuario) => ({
          value: usuario.id,
          label: `${usuario.nome} ${usuario.sobrenome}`,
        }))
      );
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar os usuários!');
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      const authToken = localStorage.getItem('RangoPassToken');

      if (!authToken) {
        throw new Error('Token de autenticação não encontrado.');
      }

      const currentDate = moment().toISOString();

      const response = await api.put(
        `/admin/restaurantes/${id}`,
        {
          status: 'deletado',
          deletedAt: currentDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      message.success('Restaurante deletado com sucesso!');
      fetchDataRestaurante(); // Atualiza a lista de usuários
    } catch (error) {
      console.error('Erro ao deletar restaurante:', error);
      message.error(`Erro ao deletar restaurante: ${error.message}`);
    }
  };

  const handleCepChange = async (e) => {
    if (!e || !e.target) {
      return;
    }
    const valor = e.target.value.replace(/\D/g, '');
    setCepRestaurante(valor);
    if (valor.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setLogradouroRestaurante(response.data.logradouro);
        setBairroRestaurante(response.data.bairro);
        setCidadeRestaurante(response.data.localidade);
        setEstadoRestaurante(response.data.uf);
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };

  const fetchBancos = async () => {
    try {
      const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
      setBancoList(response.data);
    } catch (error) {
      console.error('Erro ao buscar os bancos:', error);
    }
  };

  const mapStatus = (status) => {
    switch (status) {
      case 'ativo':
        return 'Ativo';
      case 'inativo':
        return 'Inativo';
      case 'deletado':
        return 'Deletado';
      default:
        return 'Desconhecido';
    }
  };

  const tipoList = [
    { value: 'pix', label: 'Pix' },
    { value: 'corrente', label: 'Conta Corrente' },
    { value: 'poupanca', label: 'Conta Poupança' },
  ];

  const handleFormSubmit = async () => {
    if (!nomeRestaurante || !nomeRestauranteFantasia || !docRestaurante || !emailRestaurante || !telefoneRestaurante || !categoriaRestaurante || !cepRestaurante || !numeroRestaurante || !favorecidoRestaurante || !tipo || !cpfcnpjFavorecido) {
      message.error('Por favor, preencha todos os campos!');
      return;
    }

    if (imageUrls.length === 0) {
      message.error('Por favor, escolha uma imagem!');
      return;
    }

    setLoading(true);
    try {
      const urlsFotos = await Promise.all(
        fileList.map(async (file) => {
          if (!file.url && !file.response) {
            const response = await handleUpload({ file: file.originFileObj });
            console.log('response', response)
            return response;
          }
          return file.url || file.response;
        })
      );



      const authToken = localStorage.getItem('RangoPassToken');
      const dadosRestaurante = {
        nome: nomeRestaurante,
        nomeFantasia: nomeRestauranteFantasia,
        doc: docRestaurante,
        tipo_doc: tipoDocRestaurante,
        email: emailRestaurante,
        telefone: telefoneRestaurante,
        categoriaId: categoriaRestaurante,
        status: statusRestaurante,
        aberto,
        tipo,
        recebimentoId: recebimentoRestaurante,
        uploadMedia: imagemRestaurante,
        userId: proprietario,
        cep: cepRestaurante,
        logradouro: logradouroRestaurante,
        numero: Number(numeroRestaurante),
        complemento: complementoRestaurante,
        bairro: bairroRestaurante,
        cidade: cidadeRestaurante,
        uf: estadoRestaurante,
        favorecido: favorecidoRestaurante,
        banco,
        agencia,
        digito,
        conta,
        operacao: tipo,
        doc_favorecido: cpfcnpjFavorecido,
        chavePix,
      };
      console.log('dadosRestaurante', dadosRestaurante)

      let response;

      if (modalAction === 'Cadastrar') {
        response = await api.post('/admin/restaurantes', dadosRestaurante, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        message.success('Restaurante cadastrado com sucesso!');
      } else if (modalAction === 'Salvar') {
        response = await api.put(`/admin/restaurantes/${editRecordIdRestaurante}`, dadosRestaurante, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        message.success('Restaurante atualizado com sucesso!');
      }
      fetchDataRestaurante();
      setRestauranteModalOpen(false);
    } catch (error) {
      console.error(error.message);
      console.log('e', response)
      message.error('Erro ao cadastrar/atualizar restaurante!');
    }
    setLoading(false);
  };

  const formatarTelefone = (telefone) => {
    const numeros = telefone.replace(/\D/g, '');
    if (numeros.length === 11) {
      return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (numeros.length === 10) {
      return numeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return numeros;
  };

  const formatarDocumento = (documento) => {
    const apenasNumeros = documento.replace(/\D/g, '');

    if (apenasNumeros.length === 11) {
      apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (apenasNumeros.length === 14) {
      return apenasNumeros.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }

    return documento;
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };
  

  const handleUpload = async ({ file, onSuccess, onError }) => {
    if (!isFileSizeValid(file)) {
      message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
      if (onError) {
        onError(new Error('O arquivo é muito grande.'));
      }
      return;
    }
  
    const formData = new FormData();
    formData.append('media', file);
    formData.append('type', 'imagem');
  
    try {
      const response = await api.post('/upload-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onSuccess) {
        onSuccess(response.data.message.key);
        console.log(response.data.message.url);
        setImagemRestaurante(response.data.message.key);
        console.log('response.data.message.key', response.data.message.key);
      }
      return response.data.message.key;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error('Erro ao fazer upload da imagem:', error.message);
    }
  };
  
  

  const handleFormSubmitCategoria = () => {
    fetchDataCategoria();
    setCategoriaModalVisible(false);
  };

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    applyFilters(filterValues);
  };

  const applyFilters = (filterValues) => {
    const { nome, status } = filterValues;
    let filtered = [...data];

    if (nome) {
      filtered = filtered.filter(item => item.nome.toLowerCase().includes(nome.toLowerCase()));
    }

    if (status) {
      filtered = filtered.filter(item => item.status === status);
    }

    setFilteredData(filtered);
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <a>Gerenciamento</a>,
          },
          {
            title: (
              <>
                <span>Restaurante</span>
              </>
            ),
          },
        ]}
      />

      <Button type='secundary' shape='round' icon={<IoAdd />} size={10} onClick={showLoading}>
        Restaurante
      </Button>
      
      <div className={styles.filters}>
        <div className={styles.inputsContainer}>
          <label>Restaurante</label>
          <Input
          placeholder="Filtrar por nome do restaurante"
          value={filters.nome}
          onChange={(e) => handleFilter({ ...filters, nome: e.target.value })}
          style={{ width: `100%` }}
        />
        </div>
        <div className={styles.inputsContainer}>
        <label>Status</label>
        <Select
          placeholder="Filtrar por status"
          value={filters.status}
          onChange={(value) => handleFilter({ ...filters, status: value })}
          style={{ width: `100%` }}
        >
          <Option value="">Todos</Option>
          <Option value="ativo">Ativo</Option>
          <Option value="inativo">Inativo</Option>
        </Select>
        </div>
      </div>
      
      <RestauranteTable data={filteredData} handleDelete={handleDelete} />
      
      <RestauranteModal
        loading={false}
        restauranteModalOpen={restauranteModalOpen}
        setRestauranteModalOpen={setRestauranteModalOpen}
        handleFormSubmit={handleFormSubmit}
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        openAddCategoriaModal={openAddCategoriaModal}
        nomeRestaurante={nomeRestaurante}
        setNomeRestaurante={setNomeRestaurante}
        nomeRestauranteFantasia={nomeRestauranteFantasia}
        setNomeRestauranteFantasia={setNomeRestauranteFantasia}
        docRestaurante={docRestaurante}
        setDocRestaurante={setDocRestaurante}
        emailRestaurante={emailRestaurante}
        setEmailRestaurante={setEmailRestaurante}
        telefoneRestaurante={telefoneRestaurante}
        setTelefoneRestaurante={setTelefoneRestaurante}
        categoriaRestaurante={categoriaRestaurante}
        setCategoriaRestaurante={setCategoriaRestaurante}
        categoriaList={categoriaList}
        recebimentoRestaurante={recebimentoRestaurante}
        setRecebimentoRestaurante={setRecebimentoRestaurante}
        recebimentoList={recebimentoList}
        proprietario={proprietario}
        setProprietario={setProprietario}
        proprietarioList={proprietarioList}
        cepRestaurante={cepRestaurante}
        handleCepChange={handleCepChange}
        logradouroRestaurante={logradouroRestaurante}
        setLogradouroRestaurante={setLogradouroRestaurante}
        numeroRestaurante={numeroRestaurante}
        setNumeroRestaurante={setNumeroRestaurante}
        complementoRestaurante={complementoRestaurante}
        setComplementoRestaurante={setComplementoRestaurante}
        bairroRestaurante={bairroRestaurante}
        setBairroRestaurante={setBairroRestaurante}
        cidadeRestaurante={cidadeRestaurante}
        setCidadeRestaurante={setCidadeRestaurante}
        estadoRestaurante={estadoRestaurante}
        setEstadoRestaurante={setEstadoRestaurante}
        favorecidoRestaurante={favorecidoRestaurante}
        setFavorecidoRestaurante={setFavorecidoRestaurante}
        cpfcnpjFavorecido={cpfcnpjFavorecido}
        setCpfcnpjFavorecido={setCpfcnpjFavorecido}
        banco={banco}
        setBanco={setBanco}
        bancoList={bancoList}
        tipo={tipo}
        setTipo={setTipo}
        tipoList={tipoList}
        agencia={agencia}
        setAgencia={setAgencia}
        digito={digito}
        setDigito={setDigito}
        conta={conta}
        setConta={setConta}
        chavePix={chavePix}
        setChavePix={setChavePix}
        customRequestHandler={handleUpload}
      />

      <CategoriaModal
        visible={categoriaModalVisible}
        onClose={() => setCategoriaModalVisible(false)}
        onAddCategoria={() => fetchDataCategoria()}
        onSubmit={handleFormSubmitCategoria}
        modalAction={modalActionCategoria}
        selectedCategoria={selectedCategoria}
        initialData={data.find((item) => item.id === editRecordId) || {}}
      />
    </div>
  );
};

export default Restaurante;