// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__I104F {
  width: 90%;
  min-height: 100%;
  margin: auto;
  padding: 24px 0px;
}

.style_containerModal__Bn4nd {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.style_containerUpload__UCkaW {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

.style_row__XIShV {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

.style_col__hkVM6 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_filters__jbBwt {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 0.5rem;
  padding: 24px 0px;
  border-radius: 8px;
}

.style_inputsContainer__RHfnI {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.style_inputWrapper__ZxKJa, .style_selectWrapper__\\+dJHX {
  flex: 1 1;
}

.style_buttons__hypQ0 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Produtos/style.module.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AADF;;AAIA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,SAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,oBAAA;AADF","sourcesContent":["@import '../../cores.scss';\n\n.container {\n  width: 90%;\n  min-height: 100%;\n  margin: auto;\n  padding: 24px 0px;\n}\n\n.containerModal {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.containerUpload {\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  gap: 8px;\n}\n\n.row {\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n}\n\n.col {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.filters {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  margin-bottom: .5rem;\n  padding: 24px 0px;\n  border-radius: 8px;\n}\n\n.inputsContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n}\n\n.inputWrapper, .selectWrapper {\n  flex: 1;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__I104F`,
	"containerModal": `style_containerModal__Bn4nd`,
	"containerUpload": `style_containerUpload__UCkaW`,
	"row": `style_row__XIShV`,
	"col": `style_col__hkVM6`,
	"filters": `style_filters__jbBwt`,
	"inputsContainer": `style_inputsContainer__RHfnI`,
	"inputWrapper": `style_inputWrapper__ZxKJa`,
	"selectWrapper": `style_selectWrapper__+dJHX`,
	"buttons": `style_buttons__hypQ0`
};
export default ___CSS_LOADER_EXPORT___;
