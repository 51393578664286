import React, { useState, useEffect } from 'react';
import { Breadcrumb, Table, Tooltip, Button, Modal, Input, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import { AiTwotoneEdit } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import Filtro from '../../Components/Filtro';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';

const TipoRecebimento = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Adicionar Tipo de Recebimento');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [titulo, setTitulo] = useState('');
  const [intervalo, setIntervalo] = useState(null); // Inicialize com 0 ou o valor padrão desejado
  const [valor, setValor] = useState(null); // Inicialize com 0 ou o valor padrão desejado  
  const [modalAction, setModalAction] = useState('Adicionar');
  const [data, setData] = useState([]);
  const [editRecordId, setEditRecordId] = useState(null);

  useEffect(() => {
    fetchDataTipoRecebimento();
  }, []);

  const fetchDataTipoRecebimento = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/tipo_recebimento');
      setData(response.data.message); // Verifique se response.data é um array de objetos
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar tipos de recebimento!');
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setModalTitle('Editar Tipo de Recebimento');
    setModalAction('Salvar');
    setTitulo(record.titulo);
    setIntervalo(record.intervalo);
    setValor(record.valor);
    setStatus(record.status);
    setEditRecordId(record.id); // Define o id do registro em edição
    showLoading(true);
  };

  const handleCheckboxChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const showLoading = () => {
    setOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleFilter = (filters) => {
  };

  const mapStatus = (status) => {
    switch (status) {
      case 'ativo':
        return 'Ativo';
      case 'inativo':
        return 'Inativo';
      case 'deletado':
        return 'Deletado';
      default:
        return 'Desconhecido'; // Valor padrão se o status não corresponder a nenhum caso
    }
  };

  const columns = [
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (text) => <a>{text}</a>,
      width: 150,
    },
    {
      title: 'Intervalo',
      dataIndex: 'intervalo',
      key: 'intervalo',
      width: 80,
      render: (text) => `${text} dia`
    },
    {
      title: 'Porcentagem',
      dataIndex: 'valor',
      key: 'valor',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => `${text}%`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: {
        showTitle: false,
      },
      render: (status) => (
        <Tooltip placement="topLeft" title={mapStatus(status)}>
          {mapStatus(status)}
        </Tooltip>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Button type="tertiary" icon={<AiTwotoneEdit />} size={10} onClick={() => handleEdit(record)} />
      ),
    },
  ];



  const filtroOptions = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
    { value: 'deletado', label: 'Deletado' },
    // Adicione outras opções conforme necessário
  ];

  const statusOption = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
    { value: 'deletado', label: 'Deletado' },
    // Adicione outras opções conforme necessário
  ];

  const handleFormSubmit = async () => {

    if (!titulo || intervalo === null || valor === null || !status) {
      message.error('Por favor, preencha todos os campos!');
      return;
    }

    setLoading(true);

    try {
      const authToken = localStorage.getItem('RangoPassToken');
      const dadosTipoRecebimento = {
        titulo,
        intervalo: Number(intervalo),
        valor: Number(valor),
        status
      };

      let response;

      if (modalAction === 'Adicionar') {
        response = await api.post('/admin/tipo_recebimento', dadosTipoRecebimento, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        message.success('Tipo de recebimento cadastrado com sucesso!');
      } else if (modalAction === 'Salvar') {
        response = await api.put(`/admin/tipo_recebimento/${editRecordId}`, dadosTipoRecebimento, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        message.success('Tipo de recebimento atualizado com sucesso!');
      }

      fetchDataTipoRecebimento();
      setOpen(false); // Fecha o modal após a conclusão da operação
    } catch (error) {
      console.error(error.message);
      message.error('Erro ao cadastrar/atualizar tipo de recebimento!');
    }

    setLoading(false);
  };


  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='24'
                  viewBox='0 0 23 24'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <a>Gerenciamento</a>,
          },
          {
            title: (
              <>
                <span>Tipo de Recebimento</span>
              </>
            ),
          },
        ]}
      />

      <Button type="secondary" shape="round" icon={<IoAdd />} size="large" onClick={showLoading}>
        Tipo Recebimento
      </Button>

      <Filtro
        onFilter={handleFilter}
        options={filtroOptions}
        showUsuarioInput={true}
        showTipoSelect={true}
        showButtonFiltrar={true}
      />

      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
      />


      <Modal
        title={<p>{modalTitle}</p>}
        footer={
          <div>
            <Button type="primary" onClick={handleFormSubmit}>
              {modalAction}
            </Button>
            <Button type="secondary" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </div>
        }
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <div className={styles.containerModal}>
          <div>
            <label>Título</label>
            <Input
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              placeholder="Digite o título..."
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Intervalo</label>
            <Input
              type="number"
              value={intervalo}
              onChange={(e) => setIntervalo(e.target.value)}
              placeholder="Digite o intervalo em dias..."
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Porcentagem</label>
            <Input
              type="number"
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              placeholder="Digite a porcentagem..."
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label>Status</label>
            <Select
              className={styles.select}
              value={status}
              placeholder="Selecione..."
              onChange={(value) => setStatus(value)}
              style={{ width: '100%' }}
            >
              {statusOption.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TipoRecebimento;
